var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"queryInfo":_vm.queryInfo,"keyword-placeholder":"姓名，身份证","tool-list":['grade', 'keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"非全日制专业","label-width":"120"}},[_c('el-select',{staticStyle:{"width":"260px"},attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.queryInfo.majorId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "majorId", $$v)},expression:"queryInfo.majorId"}},_vm._l((_vm.partTimeMajorList),function(ref){
var majorName = ref.majorName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":majorName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"是否已缴费","label-width":"130","prop":"payFlag"}},[_c('el-select',{staticStyle:{"width":"100px"},attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.payFlag),callback:function ($$v) {_vm.$set(_vm.queryInfo, "payFlag", $$v)},expression:"queryInfo.payFlag"}},[_c('el-option',{attrs:{"label":"是","value":1}}),_c('el-option',{attrs:{"label":"否","value":0}})],1)],1)],1),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","plain":"","size":"small"},on:{"click":function($event){return _vm.handleDownloadStuList()}}},[_vm._v("导出自定义非全日制缴费")]),[_c('upload-excel',{attrs:{"permit-key":['partTime:pay:add'],"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importPartTimePayment},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportPartTimePaymentTemplate()},"on-success":function($event){return _vm.renderTable(1)}}}),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("添加自定义非全日制缴费 ")])]],2)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"orderNo","label":"学费订单","width":"160","align":"center"}}),_c('el-table-column',{attrs:{"prop":"payFlag","label":"状态","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(+row.payFlag)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已缴")]):_c('span',{staticClass:"info"},[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"prop":"academicYear","label":"缴费学年","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" 第"+_vm._s(_vm.chineseNum[+row.academicYear||0])+"学年 ")]}}])}),_c('el-table-column',{attrs:{"prop":"amount","label":"缴费标准","width":"100"}}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"二级学院","width":"120"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业名字","width":"250"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"prop":"grade","label":"年级","width":"120"}}),_c('el-table-column',{attrs:{"prop":"idNumber","label":"身份证","width":"180"}}),_c('el-table-column',{attrs:{"prop":"examineCode","label":"考生号","width":"140"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"150"}}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['partTime:pay:edit']),expression:"['partTime:pay:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.showDialog(row.id, ("-" + (row.stuName) + "(" + (row.examineCode) + ")"))}}},[_vm._v("编辑 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['partTime:pay:edit']),expression:"['partTime:pay:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.resetPassword(row)}}},[_vm._v("重置密码 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['partTime:pay:del']),expression:"['partTime:pay:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){_vm.del(_vm.delPartTimePaymentByIdApi, row.id, ("自定义非全日制缴费-" + (row.stuName) + "(考号:" + (row.examineCode) + ")"))}}},[_vm._v(" 删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('PartTimePaymentDialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }