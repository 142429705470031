import request from '@/service/request'

// 获取学生列表
export const getStudentPartTimeList = (params) => {
  return request.post('/stu/stuInfoPartTime/page', params)
}

// 获取全部学生列表
export const getStudentPartTimeAll = (params) => {
  return request.post('/stu/stuInfoPartTime/list', params)
}

// 添加学生
export const addStudentPartTime = (params) => {
  return request.post('/stu/stuInfoPartTime/save', params)
}

// 删除学生
export const deleteStudentPartTime = (params) => {
  return request.post('/stu/stuInfoPartTime/del/' + params)
}

// 根据id获取学生信息
export const getStudentPartTimeById = (params) => {
  return request.post('/stu/stuInfoPartTime/getById/' + params)
}

// 获取继续教育学院的专业列表
export const getPartTimeMajors = () => {
  return request.post('/stu/stuInfoPartTime/getPartTimeMajors')
}

// 获取继续教育学院学生缴费统计
export const getPartTimePayCount = (params) => {
  return request.post('/stu/stuInfoPartTime/payCount', params)
}

// 获取继续教育学院学生缴费统计
export const resetStudentPartTimePasswordApi = (id) => {
  return request.post(`/stu/stuInfoPartTimeCustom/initPassWord/${id}`)
}
